'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import InviteOrComplete from './InviteOrComplete.react';
import ImgResized from '../../../components/Widgets/ImgResized.react';

import { getExpirationInfo } from '../../../pro/utils/Patients';

import AuthStore from '../../../stores/AuthStore';
import { getConfig } from '../../../utils/Env';

import './PatientCard.scss';

export default class PatientCard extends Component {
    static propTypes = {
        patient: PropTypes.object,
        hideFollowing: PropTypes.bool,
        showSendPlan: PropTypes.bool,
        rowNumber: PropTypes.number,
        pageName: PropTypes.string,
        handleSelection: PropTypes.func,
        isChecked: PropTypes.bool,
        completed: PropTypes.string,
    };

    static defaultProps = {
        hideFollowing: false,
        showSendPlan: false,
    };

    static contextTypes = {
        user: PropTypes.object,
        sendPatientInvite: PropTypes.func,
        showEditMeta: PropTypes.func,
        isMobile: PropTypes.bool,
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            following: props.patient.following,
            working: false,
            checked: false,
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (this.props.patient !== nextProps.patient) {
            this.setState({following: nextProps.patient.following});
        }
    }

    toggleFollowing = () => {
        const { patient } = this.props;
        const { following, working } = this.state;

        if (working || !(patient && patient.links && patient.links.self && patient.links.self.href)) {
            return;
        }

        this.setState({following: !following, working: true});

        // Update the patient
        AuthStore.fetch(getConfig('users_api') + patient.links.self.href, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=patient/1'},
            body: JSON.stringify({following: !following}),
        }).then(
            response => this.setState({working: false}),
            error => this.setState({working: false}),
        );
    }

    renderImportantInfo = () => {
        const { patient } = this.props;
        const { completed = [] } = patient;

        const { days, expired, expiring_soon } = getExpirationInfo(patient.active_until, patient.expiration);

        let dayText = "Today";

        if (days) {
            dayText = days + (days === 1 ? " Day" : " Days");
        }

        if (expired || expiring_soon) {
            return (
                <div className="expiration">
                    <p>Account {expired ? "Expired": (days ? "Expiring in" : "Expiring")}:</p>
                    <p>{dayText + (expired ? " Ago " : "")}</p>
                </div>
            );
        }

        if (!patient.active) {
            return (
                <div className="last-activity">
                    <p>Deactivated</p>
                </div>
            );
        }

        if (!completed.includes('anthro')) {
            return (
                <div className="last-activity">
                    <p>Incomplete Profile:</p>
                    <p>Need info</p>
                </div>
            );
        }

        if (!completed.includes('taste')) {
            return (
                <div className="last-activity">
                    <p>Incomplete Profile:</p>
                    <p>Need preferences</p>
                </div>
            );
        }

        if (!completed.includes('meals')) {
            return (
                <div className="last-activity">
                    <p>Incomplete Profile:</p>
                    <p>Need preferences</p>
                </div>
            );
        }

        if (!completed.includes('conditions')) {
            return (
                <div className="last-activity">
                    <p>Incomplete Profile:</p>
                    <p>Need {patient.practice_type === 'dietetics' ? 'conditions' : 'lifestyle'}</p>
                </div>
            );
        }

        if (!completed.includes('energy')) {
            return (
                <div className="last-activity">
                    <p>Incomplete Profile:</p>
                    <p>Need energy estimate</p>
                </div>
            );
        }

        if (!completed.includes('mealDetails')) {
            return (
                <div className="last-activity">
                    <p>Incomplete Profile:</p>
                    <p>Need preferences</p>
                </div>
            );
        }

        if (!completed.includes('nutrition')) {
            return (
                <div className="last-activity">
                    <p>Incomplete Profile:</p>
                    <p>Need nutrition</p>
                </div>
            );
        }

        //TODO: move expiration if statement here

        if (patient.next_update && moment().add(3, 'days').isAfter(patient.next_update)) {
            return (
                <div className="last-activity">
                    <p>{patient.practice_type === 'dietetics' ? 'Prescription' : 'Profile'} Update Needed:</p>
                    <p>{moment(patient.next_update).fromNow()}</p>
                </div>
            );
        }

        if (!patient.invite_sent) {
            return (
                <div className="last-activity">
                    <p>Ready to Invite:</p>
                    <p>Profile is complete</p>
                </div>
            );
        }

        if (!patient.invite_accepted &&
            patient.invite_sent &&
            moment(patient.invite_sent).diff(moment(), 'days') <= -1) {
            return (
                <div className="last-activity">
                    <p>Invite Sent:</p>
                    <p>{patient.invite_sent ? moment(patient.invite_sent).fromNow() : 'not sent yet'}</p>
                </div>
            );
        }

        if (patient.practice_type === 'dietetics') {
            return (
                <div className="last-activity">
                    <p>Last Recommendation:</p>
                    <p>{patient.last_recommendation_date ? moment(patient.last_recommendation_date).fromNow() : 'none'}</p>
                </div>
            );
        }

        if (patient.invite_accepted) {
            return (
                <div className="last-activity">
                    <p>Client Joined:</p>
                    <p>{patient.invite_accepted ? moment(patient.invite_accepted).fromNow() : 'not joined yet'}</p>
                </div>
            );
        } else {
            return (
                <div className="last-activity">
                    <p>Client Hasn't Joined</p>
                </div>
            );
        }
    }

    isPatientAlert = () => {
        const { patient } = this.props;
        const { completed = [] } = patient;

        if (!patient.active) {
            return 'inactive';
        }

        if (patient.next_update && moment().add(1, 'week').isAfter(patient.next_update)) {
            return 'finish';
        }

        if (!patient.invite_sent) {
            return 'finish';
        }

        if (!patient.invite_accepted &&
            patient.invite_sent &&
            moment(patient.invite_sent).diff(moment(), 'days') <= -1) {
            return 'finish';
        }

        if (!(completed.includes('taste') &&
              completed.includes('conditions') &&
              completed.includes('energy') &&
              completed.includes('nutrition') &&
              completed.includes('meals') &&
              completed.includes('mealDetails'))) {
            return 'finish';
        }

        return false;
    }

    getExpirationDisplay = (patient) => {
         const { days, expired, expiring_soon } = getExpirationInfo(patient.active_until, patient.expiration);
         let expirationText = patient.active ? patient.active_until : "Deactivated";
         let expirationTextColor = patient.active ? "#324253" : "#C7CED6";

        if (patient.expiration === "unlimited") {
            expirationText = "Unlimited";
        } else if (expired){
            expirationText = "Expired";
            expirationTextColor = "#C13E0E";
        } else if (expiring_soon) {
            expirationText = `Expiring Soon ${moment(patient.active_until).format('MM/DD/YYYY')}`;
            expirationTextColor = "#AD8124";
        }

        return {expirationText,expirationTextColor}
    }

    renderMessageBubble = () => {
        const { patient } = this.props;
        const { router } = this.context;

        return (
            <div
                className="message-bubble-container"
                onClick={(e) => {
                    if(patient.inhibit_jabber || !patient.active) {
                        window.open("mailto: " + patient.email, "_blank");
                    } else {
                        router.push({pathname: `/clients/${patient.uuid}/messages`});
                      }
                   e.preventDefault();
                }}
                target="_blank">
                <div className="message-bubble">
                    {patient.inhibit_jabber || !patient.active ? <i className="icon-email4" />
                    :<i className="icon-messages" />
                    }
                </div>
            </div>
        )
    }

    handleChange = (event) => {
        const { handleSelection, patient } = this.props;
        handleSelection(event.target.value);
    }

    render() {
        const { patient, hideFollowing, rowNumber, pageName, handleSelection, isChecked, completed } = this.props;
        const { sendPatientInvite, showEditMeta, isMobile } = this.context;
        const { following, working, checked } = this.state;

        const icons = {
            'male': 'avatar icon-male2',
            'female': 'avatar icon-female2',
        };

        const {expirationText,expirationTextColor} = this.getExpirationDisplay(patient)

        const conditions = (patient.conditions && patient.conditions.length > 0)
                         ? (patient.conditions || []).map(c => c.name).join(', ')
                         : '';

        const isDashboard = pageName !== "search";

        const isGroceryExport = pageName === "export-grocery";

        const family = patient.family.length + 1;

        const patientCalories = patient.target_energy_kcal || 0;

        const patientFamilyCalories = patient.family.reduce((calories, obj) => calories += ( obj.target_energy_kcal || 0 ), 0);
        const totalCalories = patientFamilyCalories + patientCalories;

        if(isDashboard && !isGroceryExport) {
            return (
                <div className="patient-card alert-card" data-even={rowNumber % 2 === 0} data-alert={this.isPatientAlert()}>
                    <Link to={`/clients/${patient.uuid}`} onClick={(e) => e.stopPropagation()}>
                        {patient.image
                            ? <ImgResized className="avatar" src={patient.image} width="154" height="154"/>
                            :<i className={icons[patient.gender] || "avatar icon-male2"} />}
                        <span className="status-dot" style={{"backgroundColor": expirationTextColor === "#324253" ? "#2DCA93": expirationTextColor}}/>
                        <div className="alert-info">
                            {patient.first_name || patient.last_name ?
                                <h4>{patient.first_name} {patient.last_name}</h4>
                            : null}

                            {!patient.first_name && !patient.last_name ?
                                <h4>NO NAME</h4>
                            : null}
                            <div className="alert-subtext">
                                {conditions.length ? <p className="condition">{conditions}</p> : null}
                                {patient.last_seen ?
                                    <span>
                                        <p data-deactivated={!patient.active} className="last-active-date">Last active:</p>
                                        <p data-deactivated={!patient.active} className="last-active-date">{moment(patient.last_seen).format('MM/DD/YY')}</p>
                                   </span>
                                : null}
                                {this.renderImportantInfo()}
                            </div>
                            <InviteOrComplete patient={patient} showEditMeta={showEditMeta} showExpiration={'patient_card'} sendPatientInvite={sendPatientInvite} className="next-step-btn" />
                            {pageName === "search" ? this.renderMessageBubble() : null}
                        </div>
                    </Link>
                </div>
            )
         } else if (isMobile && !isGroceryExport) {
         return (
                <div className="patient-card" data-even={rowNumber % 2 === 0} data-alert={this.isPatientAlert()}>
                    <Link to={`/clients/${patient.uuid}`} onClick={(e) => e.stopPropagation()}>
                        {patient.image
                            ? <ImgResized className="avatar" src={patient.image} width="154" height="154"/>
                            :<i className={icons[patient.gender] || "avatar icon-male2"} />}
                        <span className="status-dot" style={{"backgroundColor": expirationTextColor === "#324253" ? "#2DCA93": expirationTextColor}}/>
                        <div className="left-side">
                            {patient.first_name || patient.last_name ?
                                <h4>{patient.first_name} {patient.last_name}</h4>
                            : null}

                            {!patient.first_name && !patient.last_name ?
                                <h4>NO NAME</h4>
                            : null}

                            {conditions.length ? <p className="condition">{conditions}</p> : null}
                            {patient.last_seen ?
                                <span>
                                    <p data-deactivated={!patient.active} className="last-active-date">Last active:</p>
                                    <p data-deactivated={!patient.active} className="last-active-date">{moment(patient.last_seen).format('MM/DD/YY')}</p>
                               </span>
                            : null}
                        </div>
                        <div className="right-side" data-even={rowNumber % 2 === 0}>
                            {this.renderImportantInfo()}
                            <InviteOrComplete patient={patient} showEditMeta={showEditMeta} showExpiration={'patient_card'} sendPatientInvite={sendPatientInvite} className="next-step-btn" />
                            {pageName === "search" ? this.renderMessageBubble() : null}
                        </div>
                    </Link>
                </div>
            );
        } 
        else if (isGroceryExport) {
            return (
                    <div data-even={rowNumber % 2 === 0} className="patient-result-row">
                            <div className="patient-result-cell checkbox-cell">
                            <button className='el-pro-checkbox' data-checked={isChecked} value={patient.uuid} onClick={this.handleChange}/>
                            </div>
                            <div className="patient-result-cell name-cell">
                                <div className="left-side">
                                    {patient.first_name || patient.last_name ?
                                        <p className="patient-name">{patient.first_name} {patient.last_name}</p>
                                    : null}

                                    {!patient.first_name && !patient.last_name ?
                                         <p className="patient-name">NO NAME</p>
                                    : null}
                                </div>
                            </div>
                            <div className="patient-result-cell patient-cell">
                                {patient.patient_number ?? '...'}
                            </div>
                            <div className="patient-result-cell message-cell">
                                {this.renderMessageBubble()}
                            </div>
                            <div className="patient-result-cell completed-cell">
                                {completed}
                            </div>
                            <div className="patient-result-cell family-cell">
                                {family}
                            </div>
                            <div className="patient-result-cell calories-cell">
                                {totalCalories}
                            </div>
                    </div>
            )
        }
        else {
            return (
                <Link to={`/clients/${patient.uuid}`} onClick={(e) => e.stopPropagation()}>
                    <div data-even={rowNumber % 2 === 0} className="patient-result-row">
                            <div className="patient-result-cell avatar-cell">
                                {patient.image
                                    ? <ImgResized className="avatar" src={patient.image} width="154" height="154" />
                                :<i className={icons[patient.gender] || "avatar icon-male2"} />}
                                <span className="status-dot " style={{"backgroundColor": expirationTextColor === "#324253" ? "#2DCA93": expirationTextColor}}/>
                            </div>
                            <div className="patient-result-cell name-cell">
                                <div className="left-side">
                                    {patient.first_name || patient.last_name ?
                                        <p className="patient-name">{patient.first_name} {patient.last_name}</p>
                                    : null}

                                    {!patient.first_name && !patient.last_name ?
                                         <p className="patient-name">NO NAME</p>
                                    : null}
                                    {conditions.length ? <p className="condition">{conditions}</p> : null}
                                </div>
                            </div>
                            <div className="patient-result-cell message-cell">
                                {this.renderMessageBubble()}
                            </div>
                            <div className="patient-result-cell expiration-cell">
                                <p style={{color: expirationTextColor}}>{expirationText}</p>
                            </div>
                            <div data-deactivated={!patient.active} className="patient-result-cell last-seen-cell">
                                <p>{patient.last_seen ? moment(patient.last_seen).format('MM/DD/YYYY') : null}</p>
                            </div>
                            <div className="patient-result-cell alert-cell">
                                {this.renderImportantInfo()}
                            </div>
                            <div className="patient-result-cell button-cell">
                                <InviteOrComplete patient={patient} showEditMeta={showEditMeta} showExpiration={'patient_card'} sendPatientInvite={sendPatientInvite} className="next-step-btn" />
                            </div>
                    </div>
                </Link>
            )
        }

    }
}
